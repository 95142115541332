'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var ReactDom = require("react-dom");
var MultiselectInline = require("../components/MultiselectInline.bs.js");

function str(prim) {
  return prim;
}

function value(t) {
  return t._0;
}

function makeSport(name) {
  return /* Sport */{
          _0: name
        };
}

var Selectable = {
  value: value,
  searchString: value,
  makeSport: makeSport
};

var MultiSelect = MultiselectInline.Make(Selectable);

function unselected(selected) {
  var searchCollection = $$Array.map((function (sportName) {
          return /* Sport */{
                  _0: sportName
                };
        }), [
        "Badminton",
        "Tennis",
        "Baseball",
        "Swimming",
        "Volleyball",
        "Football",
        "Formula 1",
        "Squash",
        "Boxing"
      ]);
  return searchCollection.filter(function (sport) {
              return !$$Array.mem(sport, selected);
            });
}

function setSportSearch(setState, value) {
  return Curry._1(setState, (function (state) {
                return {
                        searchInput: value,
                        selected: state.selected
                      };
              }));
}

function select(setState, state, sport) {
  var selected = state.selected.concat([sport]);
  return Curry._1(setState, (function (_state) {
                return {
                        searchInput: "",
                        selected: selected
                      };
              }));
}

function deSelect(setState, state, sport) {
  var selected = state.selected.filter(function (selected) {
        return sport._0 !== selected._0;
      });
  return Curry._1(setState, (function (_state) {
                return {
                        searchInput: "",
                        selected: selected
                      };
              }));
}

function MultiselectInline__Example$Example(Props) {
  var match = React.useState(function () {
        return {
                searchInput: "",
                selected: []
              };
      });
  var setState = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "mt-4"
            }, React.createElement("h2", {
                  className: "text-xl font-semibold"
                }, "Example"), React.createElement("div", {
                  className: "mt-4"
                }, React.createElement("label", {
                      className: "block text-xs font-semibold",
                      htmlFor: "MultiselectInline__search-input-example"
                    }, "Select your sports:")), React.createElement(MultiSelect.make, {
                  placeholder: "Search sport",
                  onChange: (function (param) {
                      return setSportSearch(setState, param);
                    }),
                  value: state.searchInput,
                  unselected: unselected(state.selected),
                  selected: state.selected,
                  onSelect: (function (param) {
                      return select(setState, state, param);
                    }),
                  onDeselect: (function (param) {
                      return deSelect(setState, state, param);
                    }),
                  emptySelectionMessage: "No sport selected",
                  allItemsSelectedMessage: "You have selected all sports!"
                }));
}

var Example = {
  Selectable: Selectable,
  MultiSelect: MultiSelect,
  unselected: unselected,
  setSportSearch: setSportSearch,
  select: select,
  deSelect: deSelect,
  make: MultiselectInline__Example$Example
};

var root = document.querySelector("#Example");

if (!(root == null)) {
  ReactDom.render(React.createElement(MultiselectInline__Example$Example, {}), root);
}

exports.str = str;
exports.Example = Example;
/* MultiSelect Not a pure module */
